import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./_index.scss";
import AuthLayout from "../AuthLayout";
import { StyledLockIcon } from "../../../utils/icons";

const Index = () => {
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleSetPassword = (e) => {
    e.preventDefault();
    if (values.password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    console.log("Password successfully updated:", values.password);
    navigate("/");
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <AuthLayout>
      <div className='contentContainerUpdatePass'>
        <div className='welcome_wrapper_update_pass'>
          <div className='heading_text_update_pass'>Create New Password</div>
          <div className='welcome_text_update_pass'>Hey, your password must be different from any of your previous passwords.</div>
        </div>
        <form>
          <div className='password_fields_wrapper'>
            <div className='value_fields_name_update01'>
              <label htmlFor='new_password'>New Password</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                  className='form_control_update_pass'
                  id='new_password'
                  placeholder={isMobile ? "New Password" : "|  Enter Password"}
                  style={{
                    paddingLeft: values.password ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: values.password ? "#000000" : "#B3B3B3",
                  }}
                />
                {values.password.length > 0 && (
                  <div className='show_button' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? "Hide" : "Show"}
                  </div>
                )}
                <StyledLockIcon passwordVisible={values.password} />
              </div>
            </div>
            <div className='value_fields_name_update02'>
              <label htmlFor='confirm_password'>Confirm Password</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  type='password'
                  className='form_control_update_pass'
                  id='new_password'
                  placeholder={isMobile ? "Confirm Password" : "|  Re-Enter Password"}
                  style={{
                    paddingLeft: confirmPassword ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: confirmPassword ? "#000000" : "#B3B3B3",
                  }}
                />
                <StyledLockIcon passwordVisible={confirmPassword} />
              </div>
            </div>
          </div>
          <div className='forgot_pass_update_pass'>
            <button
              type='submit'
              className='reset_pass_update_pass'
              onClick={handleSetPassword} // Function to handle form submission
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Index;
