import React, { useState } from "react";
import "./GroupCard.scss";
import { MoreVertIcon } from "../../../../utils/icons";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../../../Components/Modals/index";

const dropdownForGroups = [
  {
    name: "Edit Group",
    icon: "/assets/images/edit_group.svg",
  },
  {
    name: "Make Group Inactive",
    icon: "/assets/images/toggle_off_group.svg",
  },
  {
    name: "Delete Group",
    icon: "/assets/images/delete_group.svg",
  },
];

const GroupCard = (props) => {
  const { el } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modal, setModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  if (!el) {
    console.error("Group data is undefined");
    return null;
  }

  const handleNavigate = () => {
    navigate("/super/group/list");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupDropdowns = (item) => {
    if (item.name === "Delete Group") {
      setSelectedAction(item);
      setModal(true);
      console.log("Action for Delete Group triggered");
    }
    handleClose();
  };

  const handleConfirmAction = () => {
    if (selectedAction?.name === "Delete Group") {
      console.log("Group Deleted!");
    }
    // else if (selectedAction?.name === "Make Group Inactive") {
    //   console.log("Group Marked as Inactive!");
    // }
    setModal(false);
  };

  return (
    <div className='card_container' onClick={handleNavigate}>
      <div>
        <div className='logo_and_status_container'>
          <div className='grp_logo_container'>
            <img src={el.group_icon} className='image_value' alt='Group Icon' />
          </div>
          <div className='grp_status_container'>
            <p className={`${el.group_status === "Active" ? "tableStatus" : "tableStatusInactive"}`}>{el.group_status}</p>
          </div>
        </div>

        <div className='grp_name_id'>
          <p className='grp_id'>{el.group_id}</p>
          <p className='grp_name'>{el.group_name}</p>
        </div>
      </div>

      <div className='company_count_dropdowns'>
        <div className='no_of_company'>{props.el.group_num} Company</div>
        <div className='dropdown_wrapper' onClick={(e) => e.stopPropagation()}>
          <MoreVertIcon
            style={{ color: "#4D4D4D", cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            autoFocus={false}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "11.042vw",
                borderRadius: "0.417vw",
                border: "1px solid #CCCCCC",
                marginTop: "10px",
                marginLeft: "-29px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
            }}>
            {dropdownForGroups.map((item, index) => (
              <MenuItem
                key={index}
                style={{
                  border: "1px solid #E6E6E6",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                sx={{
                  padding: "16px",
                }}
                onClick={() => handleGroupDropdowns(item)}>
                <img src={item.icon} alt={`${item.name} Icon`} />
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>

        {modal && (
          <CommonModal
            setIsOpenPopUp={setModal}
            open={modal}
            handleDelete={handleConfirmAction}
            item={{
              title: selectedAction?.name === "Delete Group" ? "Delete Group?" : "Make Group Inactive?",
              description:
                selectedAction?.name === "Delete Group"
                  ? "Are you sure you want to delete this group? This action is permanent and cannot be undone."
                  : "Are you sure you want to make this group inactive? You can reactivate it later if needed.",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GroupCard;
