import React from "react";
// import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import "./_index.scss";
import { EmailIcon } from "../../../utils/icons";
const Index = () => {
  const navigate = useNavigate();
  const backToAdmin = () => {
    navigate("/login");
  };
  return (
    <div className='email_sent_container'>
      <div className='icon_for_mail'>
        <EmailIcon />
      </div>
      <div className='welcome_emailsent'>
        <p className='heading_emailsent'>Your password has been successfully reset!</p>
        <p className='description_emailsent'>You can now log in with your new password.</p>
      </div>
      <div className='log_again'>
        <a className='log_in_button' onClick={backToAdmin}>
          Login
        </a>
      </div>
    </div>
  );
};
export default Index;
