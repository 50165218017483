import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const CommonMenu = ({ anchorEl, open, onClose, dropdownItems, menuStyles, menuItemStyles }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "right",
    //   }}
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "left",
    //   }}
      autoFocus={false}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "11.042vw",
          borderRadius: "0.417vw",
          border: "1px solid #CCCCCC",
          marginTop: "-10px",
          marginLeft: "-29px",
          ...menuStyles,
        },
        "& .MuiList-root": {
          padding: "0px",
        },
      }}
    >
      {dropdownItems.map((item, index) => (
        <MenuItem
          key={index}
          style={{
            border: "1px solid #E6E6E6",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            ...menuItemStyles,
          }}
          sx={{
            padding: "16px",
          }}
        >
          {item.icon}
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CommonMenu;
