import React, { useState, useEffect } from "react";
import "./_index.scss";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../AuthLayout";
import { StyledMailIcon } from "../../../utils/icons";
import { Color_Styling } from "../../../utils/constants";
import CommonButton from "../../../Components/Button";
import { useForgotPasswordMutation } from "../../../features/Authentication/loginSlice";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [forgotPassword, { isLoading, isError, isSuccess, data }] = useForgotPasswordMutation();
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);
  const navigate = useNavigate();
  const handleSendEmail = async() => {
    try{
      const result = await forgotPassword(email).unwrap();
      console.log("RESULT",result)
    }catch(err){
      console.log("err",err)
    }
    // navigate("/email-sent");
  };
  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <AuthLayout>
      <div className='contentContainerResetPass'>
        <div className='reset_pass_welcome_conatiner'>
          <div className='heading_text_reset_pass'>Reset Password</div>
          <div className='welcome_text_reset_pass'>
            Hello! Please enter your registered email address.
            <br /> We’ll send you a link to reset your password
          </div>
        </div>
        <form onSubmit={handleSendEmail} className='reset_form'>
          <div className='reset_field_wrapper'>
            <div className='reset_fields_name'>
              <label htmlFor='email'>Email Address</label>
            </div>
            <div className='reset_fields_value'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  type='email'
                  className='form_control_for_reset_values'
                  id='email'
                  placeholder={isMobile ? "Email Address" : "|  Email Address"}
                  style={{
                    paddingLeft: email ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: email ? "#000000" : "#B3B3B3",
                  }}
                />
                <StyledMailIcon emailVisible={email} />
              </div>
            </div>
          </div>

          <div className='reset_button'>
            {/* <button type='submit' className='reset_pass_button'>
              Send Email
            </button> */}
            <CommonButton
              text={"Send Email"}
              color={Color_Styling.basic}
              customTextColor={Color_Styling.white}
              fullWidth
              // inputProps={{ style: { padding: "0.833vw 0.521vw" } }}
              onClick={handleSendEmail}
              isLoading={isLoading}
              sx={{padding: "0.833vw 0.521vw"}}
            />
          </div>
        </form>

        <div className='back_to_login'>
          <a className='logo_page_nav' onClick={handleBackToLogin}>
            Back to login
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
