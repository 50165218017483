import React from 'react'
import "./auth.scss"

const UnprotectedRoute = ({children}) => {
  return (
    <div className='container'>
      {children}
    </div>
  )
}

export default UnprotectedRoute