import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { EmailIcon, KeyboardArrowDownIcon, NotificationsIcon, PersonIcon, LogoutIcon } from "../../utils/icons";
import "./profile.scss";
import { useNavigate } from "react-router-dom";

const HeaderProfile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownItems = [
    { name: "My Profile", icon: <PersonIcon />, path: "/profile" },
    { name: "Log Out", icon: <LogoutIcon />, path: "/login" },
  ];

  const handleNav = (path) => {
    navigate(path);
    if(path === "/login"){
      localStorage.removeItem('access_token');
    }
    setAnchorEl(null);
  };
  return (
    <div className='headerContainer1'>
      <div>
        <NotificationsIcon style={{ color: "#4D4D4D" }} />
      </div>
      <div className='rightProfile'>
        <div className='profile_dropdown'>
          <img src='/assets/images/profile.svg' onClick={handleClick} alt='profile' style={{ cursor: "pointer" }} />
          <div className='textSection' onClick={handleClick} style={{ cursor: "pointer" }}>
            John Doe
          </div>
          <div className='padLft'>
            <KeyboardArrowDownIcon onClick={handleClick} style={{ color: "#4D4D4D", cursor: "pointer" }} />
          </div>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            autoFocus={false}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "11.042vw",
                borderRadius: "0.417vw",
                border: "1px solid #CCCCCC",
                marginTop: "15px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
            }}>
            {dropdownItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleNav(item.path);
                }}
                style={{
                  border: "1px solid #E6E6E6",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
                sx={{
                  padding: "16px",
                }}>
                {item.icon}
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
