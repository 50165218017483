import React from 'react'

const ReportIcon = () => {
  return (
        <span class="material-symbols-outlined">
           lab_profile
        </span>
  )
}

export default ReportIcon