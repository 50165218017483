import React, { useState } from "react";
import "./_index.scss";
import { AddIcon, FilterListIcon, SearchIcon } from "../../utils/icons";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"; // MUI Checkbox

const Header = ({
  title,
  description,
  searchValue,
  onSearchChange = () => {},
  onAction1Click = () => {},
  btnText,
  searchText = "Search..",
  isIcon = true,
  isFilter = false,
  isSearchDisable = false,
}) => {
  const navigate = useNavigate();
  const [isFilterVisible, setIsFilterVisible] = useState(false); // state for filter visibility
  const [selectedFilters, setSelectedFilters] = useState([]); // state for selected filters

  // const handleAddGrp = () => {
  //   navigate("/super/group/add-group");
  // };

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible); // toggle the filter dropdown visibility
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedFilters((prevState) => (prevState.includes(value) ? prevState.filter((filter) => filter !== value) : [...prevState, value]));
  };

  return (
    <div className='headerContainer'>
      <div className='headerLeft'>
        <div className='headerTitle'>{title}</div>
        <div className='headerDescription'>{description}</div>
      </div>
      <div className='headerRight'>
        <div className='headerSearch'>
          <div className='headerFlexCenter'>
            <SearchIcon style={{ height: "18px", width: "18px", color: "#CCCCCC" }} />
          </div>
          <div>
            <input
              type='text'
              placeholder={` | ${searchText}`}
              value={searchValue}
              className='headerInput'
              onChange={(e) => onSearchChange(e.target.value)}
              disabled={isSearchDisable}
            />
          </div>
        </div>
        {isFilter &&
        <div className='headerFilter'>
          <div className='headerFlexCenter'>
            <div onClick={handleFilterClick} style={{ padding: 0, display: "flex", flexDirection: "row" }}>
              <FilterListIcon
                style={{
                  height: "18px",
                  width: "18px",
                  color: "#333333",
                  marginRight: "5px",
                  marginTop: "2px",
                }}
              />
              <div className='headerFilterText'>Filter</div>
            </div>
          </div>

          {isFilterVisible && (
            <div className='filterDropdown'>
              <FormGroup>
                <div className='filter_option'>Filter Option</div>
                <div className='sub_category_checklist'>Category</div>
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter1")} onChange={handleCheckboxChange} value='filter1' size='small' />}
                  label='Filter 1'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter2")} onChange={handleCheckboxChange} value='filter2' size='small' />}
                  label='Filter 2'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter3")} onChange={handleCheckboxChange} value='filter3' size='small' />}
                  label='Filter 3'
                />
                <hr className='hz_rule' />
                <div className='sub_category_checklist'>Utility</div>
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter4")} onChange={handleCheckboxChange} value='filter4' size='small' />}
                  label='Filter 4'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter5")} onChange={handleCheckboxChange} value='filter5' size='small' />}
                  label='Filter 5'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter6")} onChange={handleCheckboxChange} value='filter6' size='small' />}
                  label='Filter 6'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter7")} onChange={handleCheckboxChange} value='filter7' size='small' />}
                  label='Filter 7'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter8")} onChange={handleCheckboxChange} value='filter8' size='small' />}
                  label='Filter 8'
                />
                <FormControlLabel
                  className='form_styles'
                  control={<Checkbox checked={selectedFilters.includes("filter9")} onChange={handleCheckboxChange} value='filter9' size='small' />}
                  label='Filter 9'
                />
                <div className='filter_apply_reset_area'>
                  <button className='filter_reset_btn'>Reset</button>
                  <button className='filter_apply_btn'>Apply</button>
                </div>
              </FormGroup>
            </div>
          )}
        </div>
        }

        <div className='headerButton' onClick={onAction1Click}>
          {isIcon && (
            <div className='headerFlexCenter'>
              <AddIcon style={{ height: "18px", width: "18px" }} />
            </div>
          )}
          <div>{btnText}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
