import React, { useState, useRef } from "react";
import "./_index.scss";
import { PersonIcon } from "../../utils/icons";
import { ToggleOffIcon } from "../../utils/icons";

// ---------------------Modal import---------------------------------
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, TextField } from "@mui/material";
// ------------------------------------------------------------------
const Index = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState();
  const fileInputRef = useRef(null);
  const handleImageUpload = (event) => {
    console.log(event, "this a eve");
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 498,
    height: "22.708vw",
    BorderBottom: "8px solid #FDB81E",
    bgcolor: "background.paper",
    borderBottom: "8px solid #FDB81E", // Border bottom style
    borderRadius: "8px",
    p: 3,
  };

  return (
    <div className='user_profile'>
      <div className='head_container_01'>
        <div>
          <h2 className='title_01'>Profile</h2>
          <p className='subtitle_01'>Here you can manage the profile details </p>
        </div>

        <div className='form_actions_01'>
          <button type='button_01' className='btn_change_pass' onClick={handleOpen}>
            Change Password
          </button>
          <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <div>
                <p className='heading_change_pass'>Enter old password</p>
                <TextField
                  id='old-password'
                  placeholder='Enter old password'
                  type='password'
                  fullWidth
                  margin='normal'
                  style={{ marginTop: "5px" }}
                />
              </div>
              <div>
                <p className='heading_change_pass'>Enter new password</p>
                <TextField
                  id='new-password'
                  placeholder='Enter new password'
                  type='password'
                  fullWidth
                  margin='normal'
                  style={{ marginTop: "5px" }}
                />
              </div>
              <div>
                <p className='heading_change_pass'>Confirm new password</p>
                <TextField
                  id='confirm-new-password'
                  placeholder='Confirm new password'
                  type='password'
                  fullWidth
                  margin='normal'
                  style={{ marginTop: "5px" }}
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  mt: 2,
                }}>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: "#003065",
                  }}>
                  Change Password
                </Button>
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: "white",
                    color: "#333333",
                    border: "1px solid #333333",
                  }}
                  onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <button type='submit' className='btn_save'>
            Save
          </button>
        </div>
      </div>
      {/* 
      style={{
                height: "66.67px",
                width: "66.67px",
                color: "#003065",
              }} */}
      <div className='profile_container' id='modal_body'>
        {file ? (
          <>
            <div className='pfp'>
              <div className='pfp_icon'>
                <img src={file} alt='Uploaded' className='uploaded_image' />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='no_file'>
              <div className='pfp_icon'>
                <PersonIcon
                  style={{
                    height: "66.67px",
                    width: "66.67px",
                    color: "#003065",
                  }}
                />

                <button className='edit_pfp_div' onClick={() => fileInputRef.current.click()}>
                  <img src='assets/images/edit_pfp.svg' alt='Edit Profile' />
                </button>

                <input
                  type='file'
                  ref={fileInputRef} // Attach the ref to this input
                  accept='image/*'
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>
          </>
        )}

        <div className='pfp_details_container'>
          <div className='inner_pfp_details_container'>
            <div className='input_pfp_details'>
              <label>First Name</label>
              <input className='input_grp_val' type='text' placeholder='Enter first name' />
            </div>
            <div className='input_pfp_details'>
              <label>Last Name</label>
              <input className='input_grp_val' type='text' placeholder='Enter last name' />
            </div>
          </div>
        </div>

        <div className='pfp_details_container'>
          <div className='inner_pfp_details_container_01'>
            <div className='input_pfp_details'>
              <label>Email Address</label>
              <input className='input_grp_val' type='text' placeholder='Enter email address' />
            </div>
            <div className='input_pfp_details'>
              <label>Phone Number</label>
              <input className='input_grp_val' type='text' placeholder='Enter phone number' />
            </div>
          </div>
        </div>

        <div className='manage_backup'>
          <div className='left_backup'>
            <img src='assets/images/backup.svg' alt='backup pic' />
            <p className='desc_backup'> Manage Data Backup</p>
          </div>
          <div className='right_backup'>
            <ToggleOffIcon style={{ height: "40px", width: "40px", color: "#333333" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
