import React, { useState } from "react";
import "./_index.scss";
import FileInput from "./FileInput";
import CommonModal from "../../../../Components/Modals/index"
import { useCreateMutation } from "../../../../features/Group/groupSlice";
import { useToast } from "../../../ToastProvider";
// import CommonModal from "./../../../Components/Modals/index";
const Index = () => {
  const [file, setFile] = useState({
    preview:"",
    data:""
  });
  const [openPopUp, setIsOpenPopUp] = useState(false);
    const { showSuccess, showError } = useToast();
  const [create, { isLoading, isError, isSuccess, data }] = useCreateMutation();

  const handleAddImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && !selectedFile.size > 5 * 1024 * 1024) {
      setFile(
        {
        preview:URL.createObjectURL(selectedFile),
        data:selectedFile
        }
      );
    }
    else{
      setFile({
        preview:"",
        data:""
      })
      showError({
        body:"Cannot exceend more than 5Mb"
      })
    }
  };
  const handleDeleteImage = () => {
    setFile({
      preview:"",
      data:""
    });
  };
  const handleCreateGrpData = async() => {
    try{
      const formData = new FormData();
      formData.append('name', 'Developer');
      formData.append('subAdminEmail', 'aijaz.official20@gmail.com');
      formData.append('password', 'Haxard10@');
      formData.append('logo', file.data);
      // const payload ={
      //   name:"Developer",
      //   subAdminEmail:"aijaz.official20@gmail.com",
      //   password:"Haxard10@",
      //   logo:""
      // }
      const result = await create(formData).unwrap();
    }catch(err){

    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div className='add_grp_sub_admin_container'>
        <div className='head_container_01'>
          <div>
            <h2 className='title_01'>Add Group</h2>
            <p className='subtitle_01'>Fill the below details to add a Group.</p>
          </div>

          <div className='form_actions_01'>
            <button type='button_01' className='btn_cancel_01'>
              Cancel
            </button>
            <button type='submit' className='btn_create_01' onClick={handleCreateGrpData}>
              Create
            </button>
          </div>
        </div>

        <div className='add_grp_container' id='modal_body'>
          <div className='inner_add_grp_container'>
            <div className='add_grp_heading_container'>
              <div className='grp_container_heading'>Upload Group Logo</div>
              <div className='grp_container_desc'>
                Upload group logo in PNG or JPEG format. Ensure the file size is no larger than 5MB for a smooth upload experience.This logo will help
                you to personalize the group.
              </div>
            </div>

            <div className='logo_upload_container'>
              {!file.preview ? (
                <div className='add_grp_upload_image_container'>
                  <div className='inner_upload_image'>
                    <div className='inner_upload_image_desc'>
                      <p className='upload_image_head'>Drag and drop logo file to Upload</p>
                      <p className='upload_image_desc'>Maximum file size is 5 MB</p>
                    </div>
                    <div className='add_image_btn_container'>
                      <FileInput handleAddImage={handleAddImage} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='grp_icon_container'>
                  {file.preview && <img src={file.preview} alt='Selected' className='image_style' />}
                  <div style={{ position: "relative" }}>
                    <img
                      src='/assets/images/delete_without_fill.svg'
                      onClick={() => setIsOpenPopUp(true)} // Open the modal when clicked
                      style={{
                        backgroundColor: "red",
                        position: "absolute",
                        color: "white",
                        borderRadius: "50%",
                        top: "-52px",
                        left: "51px",
                        fontSize: "20px",
                        padding: "5px",
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {openPopUp && (
                    <CommonModal
                      setIsOpenPopUp={setIsOpenPopUp}
                      handleDelete={handleDeleteImage}
                      open={openPopUp}
                      item={{
                        title: "Delete Profile Image?",
                        description: "Are you sure you want to delete the profile picture? This action is permanent and cannot be undone.",
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            <div className='group_details_container'>
              <div className='inner_group_details_container'>
                <div className='input_group_details'>
                  <label>Group ID</label>
                  <input className='dark_input_val' type='text' placeholder='ID12546' />
                </div>
                <div className='input_group_details'>
                  <label>Group Name</label>
                  <input className='input_grp_val' type='text' placeholder='Enter group name' />
                </div>
              </div>
            </div>

            <div className='sub_admin_head_container'>
              <div className='sub_admin_heading'>Add Sub-Admin</div>
              <div className='sub_admin_desc'>Add the details below to create an Sub-Admin for this group.</div>
            </div>

            <div className='group_details_container'>
              <div className='inner_group_details_container'>
                <div className='input_group_details'>
                  <label>Email Address</label>
                  <input className='input_grp_val' type='text' placeholder='Enter Email Address' />
                </div>
                <div className='input_group_details'>
                  <label>Temporary Password</label>
                  <input className='input_grp_val' type='text' placeholder='Enter password' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
