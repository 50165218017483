import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import UnprotectedRoute from "../Router/UnprotectedRoute";
import Login from "../screens/Authentication/Login";
import EmailSent from "../screens/Authentication/EmailSent";
import ResetPassword from "../screens/Authentication/ResetPassword";
import AddUser from "./../screens/UserManagement/AddUser/AddUser";
import Successful from "../screens/Authentication/Successful";
import UpdatePassword from "../screens/Authentication/UpdatePassword";
import ProtectedRoute from "../Router/ProtectedRoute";
// import Dashboard from "../screens/Dashboard";
import UserManagement from "../screens/UserManagement";
// import InputData from "../screens/SuperAdmin/InputData";
// import Report from "../screens/SuperAdmin/Report";
import { ToastProvider } from "../screens/ToastProvider";
// import GroupManagement from "../screens/GroupManagement";
// import GroupTable from "../screens/GroupManagement/GroupTable";
// import AddGroup from "../screens/GroupManagement/AddGroup/index";
import Profile from "../screens/UserProfile/index";
import AccessDenied from "../screens/Authentication/AccessDenied/index";
import Dashboard from "../screens/SuperAdmin/Dashboard";
import InputData from "../screens/SuperAdmin/InputData";
import Report from "../screens/SuperAdmin/Report";
import GroupManagement from "../screens/SuperAdmin/GroupManagement";
import GroupTable from "../screens/SuperAdmin/GroupManagement/GroupTable";
import AddGroup from "../screens/SuperAdmin/GroupManagement/AddGroup/index"
import PageNotFound from "../screens/NoPageFound";

function Container() {
  
  ;
  const [roleData,setRoleData] = useState(1)

  // Check if user exists and has a role
  // const role = user ? user.role : "2";

  useEffect(()=>{
    if(localStorage.getItem('access_token')){
    const user = JSON.parse(localStorage.getItem('access_token'))
    setRoleData(user.role)
    }
  },[roleData])

  console.log("ROLE",roleData)

  const isAuthenticated = () => {
    if(localStorage.getItem('access_token')){
      return true
    }
    else{
      return false
    }
  };

  return (
    <ToastProvider>
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              Loading...
            </div>
          }>
                <Routes>

          {/*Root Route */}
          <Route
              path="/"
              element={
                isAuthenticated() ? (
                  <Navigate to={roleData === 1 ? "/super/dashboard" : "/super/dashboard"} />
                ) : (
                  <Navigate to="/login" />
                )
              }
              />
        {/* Public Routes */}
        <Route
          path='/login'
          element={
            <UnprotectedRoute>
              <Login />
            </UnprotectedRoute>
          }
        />
        <Route
          path='/page'
          element={
            <UnprotectedRoute>
              <PageNotFound />
            </UnprotectedRoute>
          }
        />


        <Route
          path='/email-sent'
          element={
            <UnprotectedRoute>
              <EmailSent />
            </UnprotectedRoute>
          }
        />
        <Route
          path='/reset-password'
          element={
            <UnprotectedRoute>
              <ResetPassword />
            </UnprotectedRoute>
          }
        />
        <Route
          path='/successful'
          element={
            <UnprotectedRoute>
              <Successful />
            </UnprotectedRoute>
          }
        />

        <Route
          path='/access-denied'
          element={
            <UnprotectedRoute>
              <AccessDenied />
            </UnprotectedRoute>
          }
        />
        <Route
          path='/update-pass'
          element={
            <UnprotectedRoute>
              <UpdatePassword />
            </UnprotectedRoute>
          }
        />

        {/* Protected Route */}
        {/* Protected Route  Super-Admin*/}
        <Route path='/super/dashboard' element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
        <Route path='/super/input' element={<ProtectedRoute><InputData /></ProtectedRoute>}/>
        <Route path='/super/report' element={ <ProtectedRoute> <Report /> </ProtectedRoute>}/>
        <Route path='/super/group' element={ <ProtectedRoute> <GroupManagement/> </ProtectedRoute>}/>
        <Route path='/super/group/list' element={<ProtectedRoute> <GroupTable /> </ProtectedRoute>}/>
        <Route path='/super/group/add-group' element={<ProtectedRoute><AddGroup /></ProtectedRoute>}/>

        <Route
          path='/profile'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path='*' element={<Navigate to={isAuthenticated() ? "/page" : "/login"} />} />
      </Routes>
        </Suspense>
      </Router>
    </ToastProvider>
  );
}

export default Container;
