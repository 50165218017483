import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        console.log(window.history,"HISTORY")
        navigate('/super/dashboard');
    //   if (window.history.length > 1) {
    //     window.history.back();
    //   } else {
    //     navigate('/'); // Redirect to the home page or any default route
    //   }
    };
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    //   backgroundColor: '#f9f9f9',
      color: '#FFFFFF',
    },
    heading: {
      fontSize: '4rem',
      margin: '0 0 20px',
    },
    paragraph: {
      fontSize: '1.5rem',
      margin: '0 0 30px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };


  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.paragraph}>Oops! The page you are looking for does not exist.</p>
      <button style={styles.button} onClick={handleGoBack}>
        Go Back
      </button>
    </div>
  );
};

export default PageNotFound;
