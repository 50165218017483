import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import "./_index.scss";
const EmailSent = () => {
  const navigate = useNavigate();
  const handleResendMail = () => {
    navigate("/login");
  };
  return (
    <div className='email_sent_container'>
      <div className='icon_for_mail'>
        <MailIcon />
      </div>
      <div className='welcome_emailsent'>
        <p className='heading_emailsent'>Email Sent</p>
        <p className='description_emailsent'>
          We have sent you and email at Adamsadler@gmail.com. Check your inbox and follow the instruction to reset your account password.
        </p>
      </div>
      <div className='forgot_pass_email_sent'>
        Did not receive the email?&nbsp;{" "}
        <a className='reset_pass_email_sent' onClick={handleResendMail}>
          Resend Email
        </a>
      </div>
    </div>
  );
};

export default EmailSent;
