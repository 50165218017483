import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Button,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import Pagination from '@mui/material/Pagination';
import { ImportExportIcon, KeyboardArrowDownIcon } from '../../utils/icons';
import "./table.scss"
import Pagination from '../Pagination';
import CommonMenu from '../Menu';
import { dropdownForGroups } from '../../utils/constants';

function DynamicTable({ columns, rows }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 75/10; // Example total pages
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (row) => {
    alert(`Edit clicked for ${row.name}`);
  };

  const handleDelete = (row) => {
    alert(`Delete clicked for ${row.name}`);
  };

  const sortedRows = React.useMemo(() => {
    if (!orderBy) return rows;
    return rows.slice().sort((a, b) => {
      const valA = a[orderBy];
      const valB = b[orderBy];
      if (valA < valB) return order === 'asc' ? -1 : 1;
      if (valA > valB) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [order, orderBy, rows]);

  return (
    <Paper>
      <TableContainer
         id="modal_body"
         className='tableContCommon'
        //  sx={{height:"calc(100vh - 275px)"}}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow className='tableRowStyling'>
              {columns.map((column) => (
                
                <TableCell key={column.id} sx={{width: column.minWidth,padding:0,height:"auto"}}>
                  <div className='headerStyle'>
                    <div className='txtLabel'>{column.label}</div>
                    <div className="padStyle"><ImportExportIcon style={{height:"16px",width:"16px"}}/></div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{borderBottom:"1px solid #F6F6F6"}}
                  >
                  {columns.map((column) => (
                    <TableCell 
                      key={column.id}
                      sx={{
                        padding:0 // Centering text in the table body
                      }}
                    >
                      {column.id === 'status' ? (
                        <div style={{padding:"15px 24px"}}>

                          <div className={`${row[column.id] === "Active" ? 'tableStatus'  : 'tableStatusInactive'}`}>
                            {row[column.id]}
                          </div>
                        </div>
                      ) : column.id === 'actions' ? (
                        <div style={{padding:"12px 24px",cursor:"pointer"}} onClick={handleClick}>
                          <div className='actionStyling'>
                            <div >
                              Actions
                            </div>
                            <div>
                              <KeyboardArrowDownIcon style={{height:"14px",width:"14px"}}/>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{padding:"15px 24px"}}>
                        {row[column.id]}
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    <div className='footer'>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <TablePagination
        component="div"
        className='rightPagination'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Result per page:" // Custom "Rows per page" text
        labelDisplayedRows={({ from, to, count }) =>
         `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
  } 
      />
      </div>
      <CommonMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        dropdownItems={dropdownForGroups}
      />
    </Paper>
  );
}

export default DynamicTable;
