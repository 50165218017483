import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./Popup_modal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 498,
  bgcolor: "background.paper",
  borderBottom: "8px solid #FDB81E", // Border bottom style
  borderRadius: "8px",
  p: 3,
};

export default function BasicModal({
  setIsOpenPopUp,
  handleDelete,
  open,
  item = {
    title: "",
    description: "",
  },
}) {
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    event.stopPropagation();
    setIsOpenPopUp(false);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <div className='modal_container_01'>
          <div className='fixed_values'>
            <div className='icon_for_modal'>
              <img src='/assets/images/delete_without_fill.svg' alt='Delete icon' className='icon_modal' />
            </div>
            <div className='heading_modal'>{item.title}</div>
            <div className='desc_modal'>{item.description}</div>
          </div>
          <div className='buttons_box'>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(e);
              }}
              className='delete_btn'
              sx={{ textTransform: "none" }}>
              Confirm
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                handleClose(e);
              }}
              className='cancel_btn'
              sx={{ textTransform: "none" }}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
