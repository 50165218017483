const FileInput = ({ handleAddImage }) => {
  return (
    <div
      style={{
        position: "relative",
        top: "25px",
        width: "68px",
        height: "28px",
      }}>
      <label
        htmlFor='fileInput'
        style={{
          display: "inline-block",
          backgroundColor: "#f6f6f6",
          color: "#b3b3b3",
          borderRadius: "4px",
          cursor: "pointer",
          width: "68px",
          height: "28px",
          border: "1px solid #b3b3b3",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        Browse
      </label>

      <input
        id='fileInput'
        type='file'
        accept='image/*'
        onChange={handleAddImage}
        style={{
          opacity: 0,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default FileInput;
