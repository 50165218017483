import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import { loginSlice } from '../features/Authentication/loginSlice';
import { groupSlice } from '../features/Group/groupSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [loginSlice.reducerPath]:loginSlice.reducer,
    [groupSlice.reducerPath]:groupSlice.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginSlice.middleware)
      .concat(groupSlice.middleware),
});
