import React, { useEffect, useState } from 'react'
import { SIDEBAR_DATA } from '../../utils/constants'
import "./sidebar.scss"
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
   const [selectedId, setSelectedId] = useState(() => {
      const savedId = localStorage.getItem('selectedSidebarId');
      return savedId ? parseInt(savedId, 10) : 1;
   });
   const navigate = useNavigate()
   const location = useLocation()

   // console.log("LOKK",location)

   const handleSidebarClick = (itm) => {
      setSelectedId(itm.id);
      // localStorage.setItem('selectedSidebarId', itm.id);
      navigate(itm.path);
   };
   // useEffect(() => {
   //    // Navigate to the saved path on reload
   //    const savedPath = SIDEBAR_DATA.find(item => item.id === selectedId)?.path;
   //    if (savedPath) navigate(savedPath);
   // }, [selectedId, navigate]);

   return (
      <div className='sidebarContainer'>
         {
            SIDEBAR_DATA?.map((itm, ind) => {
               return (
                  <div key={ind} className={`sidebarContent ${location.pathname.includes(itm.path) ? 'active' : ''}`}
                     onClick={
                        () => {
                           handleSidebarClick(itm)
                        }

                     }
                  >
                     <div className='sidebarList'>
                        <div>
                           <itm.icon />
                        </div>
                        <div className='sidebarTxt'>
                           {itm.text}
                        </div>
                     </div>
                     <div className={`sidebarRight ${location.pathname.includes(itm.path) ? 'active' : ''}`}>

                     </div>
                  </div>
               )
            })
         }
      </div>
   )
}

export default Sidebar