import React, { useState } from 'react'
import Header from '../../../../Components/Header';
import DynamicTable from '../../../../Components/Table';
import { columns, rows } from '../../../../utils/constants';

const GroupTable = () => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (value) => {
    setSearchValue(value);
    console.log('Search Value:', value);
  };

  const handleAction1Click = () => {
    console.log('Action 1 Clicked');
  };
  return (
    <div>
    <Header
      title="CPUC"
      description="#11542"
      searchValue={searchValue}
      isSearchDisable={true}
      btnText="Add Group"
      searchText="Search Group"
      isIcon={true}
      isFilter={false}
      onSearchChange={handleSearchChange}
      onAction1Click={handleAction1Click}
     />
    <DynamicTable columns={columns} rows={rows} />
  </div>
  )
}

export default GroupTable