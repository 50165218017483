import React from "react";
import "./layout.scss";

const AuthLayout = ({ children }) => {
  return (
    <div className='wrapperContainer'>
      <div className='logoContainer'>
        <img src='/assets/images/ecg_logo.svg' alt='Logo' className='logo_design' />
      </div>
      {children}
    </div>
  );
};

export default AuthLayout;
