import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import "./auth.scss"
import { MenuIcon } from '../utils/icons';
import HeaderProfile from '../screens/Profile/HeaderProfile';
import Sidebar from '../screens/Sidebar';
import SideMobile from '../screens/Sidebar/SideMobile';

const ProtectedRoute = ({ children }) => {
  // const isAuthenticated = localStorage.getItem('access_token')
  const [isOpen,setIsOpen] = useState(false)
  console.log("Protect",localStorage.getItem('access_token'))
  if(localStorage.getItem('access_token')){
    const {email,role} = JSON.parse(localStorage.getItem('access_token'))
    
  }
  // console.log("hkj",console.log(process.env.PUBLIC_URL))

  return localStorage.getItem('access_token') ? 
  <div className='protectedContainer'>
      {/* Header - Spans Full Width */}
      <div className='header'>
        <div className='hamburger'>
         <div className='insideHam' onClick={()=>setIsOpen(!isOpen)}>
           <MenuIcon/>
          {
            isOpen && (
              <div className='menuLIst'>
              <SideMobile/>
              </div>
            )
          }
         </div>
        </div>
        <div></div>
        <div className='rightSection'>
         <HeaderProfile/>
        </div>
      </div>
      <div className="logo-container">
        <img src="/assets/images/ecg_logo.svg" alt="Logo" className="logo" />
      </div>

      {/* Main Content Area */}
      <div className='main-content'>
        {/* Sidebar */}
        <div className='sidebar'>
          <Sidebar/>
        </div>

        {/* Content Section */}
        <div className='content'>
          {children}
        </div>
      </div>
    </div> 
  : 
  <Navigate to="/login" replace />;
};

export default ProtectedRoute;
