import React, { useState } from "react";
import "./_index.scss";
import GroupCard from "./GroupCard/GroupCard";
import Header from "../../../Components/Header";
import { useNavigate } from "react-router-dom";

const val = [
  {
    group_id: "#11542",
    group_name: "CPUC Group",
    group_status: "Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
  {
    group_id: "#11542",
    group_name: "GROUP 02",
    group_status: "In-Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
  {
    group_id: "#11542",
    group_name: "Group 03",
    group_status: "Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
];

// const val = []
const GroupManagement = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (value) => {
    setSearchValue(value);
    console.log("Search Value:", value);
  };

  const handleAction1Click = () => {
    navigate("/super/group/add-group");
    console.log("Action 1 Clicked");
  };
  return (
    <div className='grp_container'>
      <Header
        title='Group Management'
        description='Here,you can manage the groups'
        searchValue={searchValue}
        isSearchDisable={true}
        btnText='Add Group'
        searchText='Search Group'
        isIcon={true}
        // isFilter={true}
        onSearchChange={handleSearchChange}
        onAction1Click={handleAction1Click}
      />
      <div className={`grp-Content ${!val.length > 0 ? "found  " : ""}`}>
        {val.length > 0 ? (
          val.map((el, index) => {
            return <GroupCard el={el} />;
          })
        ) : (
          <div>No Group Found</div>
        )}
      </div>
    </div>
  );
};

export default GroupManagement;
