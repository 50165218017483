import React, { useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Container from "./container";


function App() {

  return (
     <Container/>
  );
}

export default App;
