import React from 'react';
import './Pagination.css'; // Optional: Add your styles here

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={`page-number ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination">
      <button onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1} className='Prev'>
        Back
      </button>
      {renderPageNumbers()}
      <button onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages} className='Prev'>
        Next
      </button>
    </div>
  );
};

export default Pagination;
