import React from "react";
import "./_index.scss";
import { useNavigate } from "react-router-dom";
import { PriorityHighIcon } from "../../../utils/icons";
const Index = () => {
  const navigate = useNavigate();
  const backToAdmin = () => {
    navigate("/login");
  };
  return (
    <div className='access_denied_container'>
      <div className='icon_for_mail_ad'>
        <PriorityHighIcon />
      </div>
      <div className='welcome_emailsent'>
        <p className='heading_emailsent'>Access Denied</p>
        <p className='description_emailsent'>You are not authorised to change the password</p>
      </div>
      <div className='log_again_access_denied'>
        <a className='log_in_button' onClick={backToAdmin}>
          Login
        </a>
      </div>
    </div>
  );
};

export default Index;
