import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseIcon, DoneIcon, PriorityHighIcon } from "../../utils/icons";

// Custom Toast Component
const CustomToast = ({ type, message, closeToast }) => {

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      padding: "16px",
      borderRadius: "8px",
      borderLeft: type === "success" ? "3px solid #4AC843" : "3px solid #EC221F",
      color: "#fff",
      gap: "16px",
      boxShadow: "0px 4px 24px 0px #00000059",
      backgroundColor: "#001022",
    },
    icon: {
      width: "40px",
      height: "40px",
      marginRight: "12px",
    },
    textContainer: {
      flex: 1,
    },
    textContent: {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "400",
      whiteSpace: "nowrap",
      fontFamily: "'Source Sans 3', sans-serif"
      // fontFamily:""
    },
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "4px",
    },
    closeButton: {
      background: "none",
      border: "none",
      color: "#fff",
      fontSize: "16px",
      cursor: "pointer",
      // marginTop:"-2px"
      // marginLeft: 'auto',
    },
    correctDiv:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      width:"24px",
      height:"24px"
    },
    closeDiv: {
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      color: "#000000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor:"pointer"
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.correctDiv}>
      {type === "success" ?
        <DoneIcon /> : <PriorityHighIcon/>
      }
      </div>
      {/* <div style={styles.title}>{message.title || (type === 'success' ? 'Success' : 'Error')}</div> */}
      <div style={styles.textContent}>{message.body}</div>
      <div style={styles.closeDiv} onClick={closeToast}>
        <CloseIcon style={{ height: "18px", width: "18px" }} />
      </div>
    </div>
  );
};

// Context for Toast
const ToastContext = createContext({
  showSuccess: (message) => {},
  showError: (message) => {},
});

export const ToastProvider = ({ children }) => {
  toast.dismiss();
  const showSuccess = (message) => {
    toast(<CustomToast type='success' message={message} />, {
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showError = (message) => {
    toast.dismiss();
    toast(<CustomToast type='error' message={message} />, {
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    });
  };

  return (
    <ToastContext.Provider value={{ showSuccess, showError }}>
      {children}
      <ToastContainer
        style={{ top: "16px", right: "16px", width: "auto" }}
        autoClose={2000}
        hideProgressBar
        closeButton={false} // Disable the default close button
        // closeOnClick
        pauseOnHover
        toastClassName={() => "custom-toast-container"}
        bodyClassName={() => "custom-toast-body"}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
